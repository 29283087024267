/* Facts container styles */
.facts-container {
  display: flex;
  flex-direction: column;
  background-color: #333;
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

/* Tab navigation styles */
.tab-navigation {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px;
  background-color: #222;
  border-bottom: 1px solid #444;
  box-sizing: border-box;
}

.tab-navigation button {
  background-color: #444;
  border: none;
  color: #e0e0e0;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  transition: background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}

.tab-navigation button.active {
  background-color: #007BFF;
  color: white;
}

/* Modular content styles */
.modular-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0; /* Removed padding to prevent overflow */
  box-sizing: border-box;
}

/* Modular boxes */
.modular-boxes {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  box-sizing: border-box;
}

.modular-box {
  background-color: #444;
  padding: 15px;
  border: 1px solid #555;
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.modular-box h3 {
  color: #007BFF;
  margin-bottom: 10px;
}

/* Bullet points */
.bullet-points {
  list-style-type: disc;
  padding-left: 20px;
}

.bullet-points li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 1.2em;
  color: #ddd;
}

.bullet-points li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #007BFF;
  font-size: 1.2em;
  line-height: 1em;
}

/* Stats section styles */
.stats-section {
  overflow-y: auto;
  max-height: 100%;
  padding-right: 10px;
  padding: 15px;
  width: 100%;
}

.stats-section h3 {
  color: #007BFF;
  margin: 20px 0 15px 0;
  font-size: 1.2em;
}

.stats-section table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
  background-color: #444;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #555;
}

.stats-section th, .stats-section td {
  padding: 10px 15px;
  border-bottom: 1px solid #555;
  border-right: 1px solid #555;
}

.stats-section th {
  background-color: #333;
  color: #007BFF;
  font-weight: 500;
  text-align: left;
}

.stats-section tr:hover td {
  background-color: #4a4a4a;
}

/* Cell classes */
td.positive {
  color: #00ff00;
  background-color: rgba(40, 167, 69, 0.15);
}

td.negative {
  color: #ff4444;
  background-color: rgba(220, 53, 69, 0.15);
}

/* Notebook styles */
.notebook {
    display: flex;
    height: 100%;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    min-height: calc(60vh - 95px);
  }
  
  .notes-list {
    width: 30%;
    background-color: #333;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #444;
    position: relative;
    height: 100%;
  }
  
  .notes-list.collapsed {
    width: 40px;
  }
  
  .notes-list-header {
    flex-shrink: 0;
    height: 40px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #333;
    border-bottom: 1px solid #444;
    display: flex;
    align-items: center;
    padding: 0;
  }
  
  .toggle-sidebar-btn {
    background: none;
    border: none;
    color: #007BFF;
    font-size: 24px;
    cursor: pointer;
    padding: 0 5px;
    transition: color 0.3s;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .toggle-sidebar-btn:hover {
    color: #0056b3;
  }
  
  .add-note-btn {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    background-color: #444;
    border: none;
    color: #007BFF;
    font-size: 24px;
    cursor: pointer;
    position: relative;
    margin-left: auto;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .add-note-btn:hover {
    background-color: #007BFF;
    color: white;
  }
  
  .notes-list-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .notes-list.collapsed .add-note-btn {
    display: none;
  }
  
  .notes-list.collapsed .notes-list-content {
    display: none;
  }
  
  .note-item {
    display: flex;
    align-items: center;
    background-color: #444;
    padding: 8px 12px;
    margin-bottom: 5px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    justify-content: space-between;
  }
  
  .note-item:hover {
    background-color: #555;
    transform: translateX(2px);
  }
  
  .note-item.active {
    background-color: #007BFF;
    color: white;
    transform: translateX(4px);
  }
  
  .note-title {
    padding-right: 8px;
  }
  
  .delete-note-btn {
    background: none;
    border: none;
    color: #bbb;
    font-size: 18px;
    cursor: pointer;
    padding: 0 5px;
    transition: color 0.3s;
    opacity: 0.7;
    line-height: 1;
  }
  
  .delete-note-btn:hover {
    color: #007BFF;
    opacity: 1;
  }
  
  .note-item.active .delete-note-btn {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .note-item.active .delete-note-btn:hover {
    color: #007BFF;
  }
  
  .note-editor {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #333;
    min-height: 100%;
  }
  
  .note-title-input {
    font-size: 1.5em;
    margin-bottom: 15px;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    background-color: #444;
    color: white;
    transition: background-color 0.2s ease;
  }
  
  .note-title-input:focus {
    background-color: #555;
    outline: none;
  }
  
  .note-content-input {
    flex-grow: 1;
    resize: none;
    padding: 12px;
    border: none;
    border-radius: 6px;
    background-color: #444;
    color: white;
    line-height: 1.5;
    transition: background-color 0.2s ease;
  }
  
  .note-content-input:focus {
    background-color: #555;
    outline: none;
  }
  
  /* Scrollbar styling for notes list */
  .notes-list::-webkit-scrollbar {
    width: 6px;
  }
  
  .notes-list::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 3px;
  }
  
/* Add these styles with proper sizing */
.document-viewer-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 0; /* Ensure no padding */
  margin: 0;  /* Ensure no margin */
  box-sizing: border-box;
}

.document-viewer {
  height: 100%;
  width: 100%;
  background-color: #333;
  padding: 0; /* Remove padding to prevent content cutoff */
}

.document-iframe {
  width: 100%;
  height: 100%;
  border: none;
  background-color: white;
  border-radius: 3px;
  overflow-x: hidden !important;
  overflow-y: auto;
  display: block; /* Ensure proper box model */
  box-sizing: border-box; /* Include padding in width/height calculations */
  margin: 0; /* Remove any margins */
  padding: 0; /* Remove any padding */
}
  