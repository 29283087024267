
/* Workflow box */
.workflow-box {
  background-color: #333;
  border-radius: 8px;
  padding: 15px;
  overflow-y: auto;
  max-height: calc(35vh - 25px);
  height: calc(35vh - 55px);
  min-height: 200px;
  margin-top: 5px;
}

/* Workflow items */
.workflow-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #444;
  margin-bottom: 10px;
  border-radius: 8px;
  position: relative;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.workflow-item:hover {
  background-color: #555;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.workflow-item input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  accent-color: #007BFF;
}

.workflow-item label {
  flex-grow: 1;
  cursor: pointer;
  margin: 0;
  color: #e0e0e0;
}

.remove-btn {
  background: none;
  border: none;
  color: #bbb;
  font-size: 16px;
  cursor: pointer;
  padding: 0 5px;
  transition: color 0.3s;
}

.remove-btn:hover {
  color: #007BFF;
}

.add-document-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-document-btn:hover {
  background-color: #0056b3;
}

.document-dropdown {
  position: absolute;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px;
  padding: 10px;
  margin-top: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 20%;
  z-index: 10;
}

.document-dropdown-item {
  padding: 8px;
  cursor: pointer;
  color: #e0e0e0;
  font-size: 14px;
  border-bottom: 1px solid #444;
  transition: background-color 0.3s;
}

.document-dropdown-item:hover {
  background-color: #444;
  color: #007BFF;
}