/* Query box */
.query-box {
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
  overflow: hidden;
}

.query-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 80px;
}

.message {
  margin: 15px 0;
  word-wrap: break-word;
  max-width: 90%;
}

/* Remove any unwanted margins when messages are next to each other */
.message + .message {
  margin-top: 5px;
}

/* User queries */
.user-query {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.user-query-bubble {
  background-color: #007BFF;
  color: white;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 80%;
  margin-left: auto;
  font-size: 15.0px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  word-break: break-word;
}

/* Query input area */
.query-input-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c2c2c;
  padding: 20px;
  border-top: 1px solid #444;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.query-input-wrapper {
  display: flex;
  gap: 12px;
  margin-top: auto;
  max-width: 100%;
  background: #333;
  padding: 6px;
  border-radius: 25px;
  border: 1px solid #444;
  transition: border-color 0.2s ease;
}

.query-input-wrapper:focus-within {
  border-color: #007BFF;
}

.query-input {
  flex: 1;
  padding: 12px 20px;
  border-radius: 20px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 14px;
  width: auto !important;
}

.query-input:focus {
  outline: none;
}

.query-input::placeholder {
  color: #888;
}

.query-submit-btn {
  padding: 10px 10px;
  background-color: #007BFF;
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.query-submit-btn:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.query-submit-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Response styling */
.response-text {
  white-space: normal;
  line-height: 1.5;
  color: #fff;
  padding: 10px 0;
  font-size: 15.6px;
}

.response-text p {
  margin: 0.5em 0;
}

.response-text strong {
  color: #fff;
  font-weight: 600;
}

.response-text br {
  display: block;
  content: "";
  margin-top: 0.5em;
}

.response-text ul, 
.response-text ol {
  margin: 0.5em 0;
  padding-left: 1.5em;
}

.response-text li {
  margin: 0.25em 0;
}

.response-text h3 {
  color: #007BFF;
  margin: 1rem 0 0.5rem 0;
}

.response-text h3 + p {
  margin-top: 0.5rem;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2c2c2c;
}

::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}