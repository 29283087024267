.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  background-color: #222;
  color: white;
  height: 100%;
  padding: 20px 0 20px 0;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  box-sizing: border-box;
}

.tool {
  width: 72%;
  padding: 15px 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px; /* Rounded corners for each tool */
  margin: 8px 0; /* Space between tools */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tool:hover {
  background-color: #333; /* Lightens background on hover */
}

.tool.active {
  background-color: #007BFF; /* Blue highlight for active tool */
  color: white;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
}

.tool span.icon {
  font-size: 28px;
  color: #bbb;
  transition: color 0.3s;
}

.tool.active span.icon,
.tool:hover span.icon {
  color: #fff; /* White icon color on active/hover */
}

.tool span.label {
  font-size: 14px;
  font-weight: 500;
  color: #ccc;
  transition: color 0.3s;
}

.tool.active span.label,
.tool:hover span.label {
  color: #fff; /* White label color on active/hover */
}
