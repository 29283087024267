.overview-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  height: calc(100vh - 130px);
}

.left-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

.right-container {
  display: grid;
  grid-template-rows: 25% 70%;
  gap: 20px;
}

.stats-panel,
.brief-panel,
.news-panel,
.query-panel {
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 15px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #333;
  border-radius: 4px;
  font-size: 14px;
}

.stat-item span:first-child {
  color: #888;
}

.stat-item span:last-child {
  font-weight: 500;
  color: #fff;
}

.brief-panel {
  display: flex;
  flex-direction: column;
}

.brief-panel h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.company-logo {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.news-item {
  padding: 12px 15px;
  margin-bottom: 12px;
  background-color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
}

.news-item:hover {
  background-color: #404040;
}

.query-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.query-panel .query-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
}

.query-panel .query-messages {
  padding-top: 60px;
}

.query-panel h2 {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  margin: 0;
}

.query-panel .query-box {
  padding: 0;
}

.query-panel .query-input-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 20px;
  background-color: #2c2c2c;
  border-top: 1px solid #444;
}

.query-panel .query-input-wrapper {
  max-width: 100%;
  margin: 0;
}

.query-panel .query-input {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.query-responses {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 15px;
}

h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 15px 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2c2c2c;
}

::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
} 