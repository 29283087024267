/* Document viewer section styles */
.document-viewer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0; /* Removed padding */
  margin: 0;  /* Removed margin */
  background-color: #222;
}

/* Document iframe */
.document-iframe {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden; /* Prevent horizontal scrolling */
  background-color: white;
  box-sizing: border-box;
}