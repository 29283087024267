.grid-container {
  display: grid;
  grid-template-columns: 54% 46%;  
  height: calc(100vh - 120px); /* Increased space for header and padding */
  min-height: 0; /* Allow container to shrink */
  gap: 30px;
  padding: 10px 35px 30px 15px;  /* Increased bottom padding */
  background-color: #222;
  color: white;
  box-sizing: border-box;
  overflow: hidden;
}

/* Add these styles to position WorkflowBox absolutely */
.workflow-box-container {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0; /* Allow container to shrink */
  overflow: auto;
}

/* Add styles for QueryBox container */
.query-box-container {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
  min-height: 0;
  position: relative;
  overflow: hidden;
}

/* Update QueryBox layout styles */
.query-messages {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0 0 100px 0; /* Increased padding for input */
}

.message {
  margin-bottom: 15px;
  word-wrap: break-word;
}

/* Remove or comment out these conflicting styles */
/*
.query-input-container,
.query-input-wrapper,
.query-input,
.submit-button {
  // Remove these styles as they're now handled in QueryBox.css
}
*/
