.stock-widget {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    margin-top: 20px;
  }
  
  .stock-widget h3 {
    margin-bottom: 10px;
  }
  
  .stock-widget p {
    margin: 5px 0;
  }
  
.stock-data-container {
  padding: 20px;
  background: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.stock-header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.stock-header h2 {
  margin: 0;
  color: #fff;
  font-size: 1.5em;
}

.view-selector {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.view-button {
  padding: 10px 20px;
  border: 1px solid #333;
  border-radius: 4px;
  background: #2c2c2c;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.view-button:hover {
  background: #383838;
}

.view-button.active {
  background: #4a4a4a;
  border-color: #666;
}

.timeframe-selector {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.timeframe-button {
  padding: 8px 16px;
  border: 1px solid #333;
  border-radius: 4px;
  background: #2c2c2c;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9em;
}

.timeframe-button:hover {
  background: #383838;
}

.timeframe-button.active {
  background: #4a4a4a;
  border-color: #666;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.metric-item {
  padding: 15px;
  background: #2c2c2c;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid #333;
}

.metric-label {
  font-size: 0.9em;
  color: #999;
}

.metric-value {
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
}

.metric-value.positive {
  color: #32CD32;
}

.metric-value.negative {
  color: #ff4d4d;
}

.chart-container {
  height: 400px;
  margin-top: 20px;
  background: #2c2c2c;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #333;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2em;
  color: #999;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  